//src/service/http.service.js

import httpService from './http.service.js';

const api = {
  async get(url, config) {
    return await httpService.get(url, config);
  },

  async post(url, data, config) {
    return await httpService.post(url, data, config);
  },

  async put(url, data, config) {
    return await httpService.put(url, data, config);
  },
  async delete(url, data, config){
    return await httpService.delete(url, config, data);
  }

  // Add more methods as needed
};
export default api;
