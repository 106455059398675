// challenge.router.js

/**
 * At a later date, move it to the Club router.
 * When added as a child, duplicate routes were coming up.
 * Both as root and as children of Club
 */
export default [

  {
    // List challenges for user
    path: 'challenges',
    name: 'my-challenges',
    component: () => import('@/views/challenges/challengeList.vue'),
    //props: route => ({clubSlug: route.params.clubSlug})  // Pass clubSlug as prop
    props: true,
    meta: { requiresAuth: true }
  },
  {
    // List challenges
    path: 'clubs/:clubSlug/challenges',
    name: 'challenges',
    component: () => import('@/views/challenges/challengeList.vue'),
    //props: route => ({clubSlug: route.params.clubSlug})  // Pass clubSlug as prop
    props: true,
    meta: { requiresAuth: true }
  },
  {
    // Show challenge Details
    path: 'clubs/:clubSlug/challenges/:challengeSlug',
    name: 'challenge',
    component: () => import('@/views/challenges/challengeDetail.vue'),
    meta: { requiresAuth: true }
    //props: true  // challengeSlug will be automatically passed as a prop
  },
  {
    // Create a new challenge
    path: 'clubs/:clubSlug/challenges/new',
    name: "new-challenge",
    component: () => import('@/views/challenges/challengeEditPage.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    // Edit existing challenge
    path: 'clubs/:clubSlug/challenges/:challengeSlug/edit',
    name: "edit-challenge",
    component: () => import('@/views/challenges/challengeEditPage.vue'),
    props: true,  // challengeSlug will be automatically passed as a prop
    meta: { requiresAuth: true }
  },
];
