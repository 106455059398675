export default [
  {
    path: 'activities',
    name: 'activities-list',
    component: () => import(/* webpackChunkName: "activities" */ '@/views/activity/activityPage.vue'),
    meta: { requiresAuth: true }
  },
/*
  {

    path: 'activities/:activityId',
    name: 'activity',
    component: () => import(/!* webpackChunkName: "clubdetail" *!/ '@/views/clubs/clubDetail'),
    props: true
  },
  {
    path: 'club/create-club',
    name: "create-club",
    component:() => import('@/components/ClubCreationForm.vue'),
  }
*/

];
