import {createRouter, createWebHistory} from 'vue-router'

// Import all routers
import AuthRouter from "@/router/auth.router";
import ClubRouter from "@/router/club.router";
import {useAuthStore} from "@/store/auth.store";
import ConnectRouter from "@/router/connect.router";
import ActivityRouter from "@/router/activity.router";
import ChallengeRouter from "@/router/challenge.router";
import JoinRequestRouter from "@/router/joinRequest.router";


const routes = [
  {
    path: '/',
    component: () => import('@/layouts/default/UpdatedDefaultLayout'),
    children: [
      {
        // We get to this post login
        path: '',
        redirect: {name: 'dashboard'},
        meta: {requiresAuth: true}
      },
      {
        // We get to this post login
        path: 'dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
        meta: {requiresAuth: true}
      },
      {
        // We get to this post login
        path: '/user/profile',
        name: 'user-profile',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/ProfilePage'),
        meta: {requiresAuth: true}
      },

      ...ClubRouter,
      ...ChallengeRouter,
      ...ConnectRouter,
      ...ActivityRouter,
      ...JoinRequestRouter,

    ],
  },

  ...AuthRouter,

]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  //console.log("from:", from.path, "to:", to.path)
  const store = useAuthStore()

  if (to.matched.some(record => record.meta.requiresAuth && !store.isAuthenticated)) {
    // Store the intended path for redirecting after login
    store.setRedirectPath(to.fullPath);
    //console.log("store state", store.$state);
    next({
      name: 'auth-login',
      query: {redirect: to.fullPath},  // This will help redirect after login
      replace: true
    });
  } else {
    next();
  }

})

export default router
