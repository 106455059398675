export default [
  {
    path: 'connect',
    name: 'connect-list',
    component: () => import(/* webpackChunkName: "thirdPartyConnectList" */ '@/views/connect/thirdPartyConnects'),
    meta: {requiresAuth: true}
  },
  {

    path: 'connect/callback/:provider',
    name: 'connect-callback',
    component: () => import(/* webpackChunkName: "thirdPartyConnectList" */ '@/views/connect/thirdPartyConnects'),
    props: true,
    meta: {requiresAuth: true}
  },
];
