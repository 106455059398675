export default [
  {
    path: 'request/:token/:action',
    name: 'request-action',
    component: () => import( '@/views/joinRequest/requestPage.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: 'requests',
    name: 'pending-requests',
    component: () => import( '@/views/joinRequest/requestPage.vue'),
    meta: {requiresAuth: true}
  }
];
