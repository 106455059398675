// club.router.js

export default [
  {
    path: 'clubs',
    name: 'club-list',
    component: () => import('@/views/clubs/clubList'),
    meta: { requiresAuth: true }
  },
  {
    path: 'clubs/:clubSlug',  // Rename :urlSlug to :clubSlug
    name: 'club',
    component: () => import('@/views/clubs/clubDetail'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: 'clubs/new',
    name: "new-club",
    component: () => import('@/views/clubs/clubEditPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: 'clubs/:clubSlug/edit',  // Rename :urlSlug to :clubSlug
    name: "edit-club",
    component: () => import('@/views/clubs/clubEditPage.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
];
