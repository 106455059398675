// stores/profile.store.js
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { ProfileService } from '@/service/profile.service'; // Import your data fetching function

export const useProfileStore = defineStore('profile', () => {
  const profileData = ref(null);

  async function fetchProfileData() {
    try {
      const data = await ProfileService.fetchProfile();
      profileData.value = data;
    } catch (error) {
      console.error('Error fetching profile data:', error);
      profileData.value = null; // Handle the error appropriately
    }
  }

  return { profileData, fetchProfileData };
});
